<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-back-button></ion-back-button>
                </ion-buttons>
                <ion-title>Nuova presenza</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <ion-header collapse="condense">
                <ion-toolbar>
                    <ion-buttons slot="start">
                        <ion-back-button></ion-back-button>
                    </ion-buttons>
                    <ion-title size="large">Nuova presenza</ion-title>
                </ion-toolbar>
            </ion-header>

            <ion-grid>
                <form @submit.prevent="creaPresenza()">
                    <ion-list>
                        <ion-row>
                            <ion-col size="12">
                                <ion-item>
                                    <ion-label>
                                        Data ingresso
                                        <ion-text color="danger"><strong>*</strong></ion-text>
                                    </ion-label>
                                    <ion-datetime
                                        name="date_start"
                                        display-format="DD/MM/YYYY"
                                        picker-format="DD/MM/YYYY"
                                        slot="end"
                                        doneText="OK"
                                        cancelText="Annulla"
                                        v-model="presenza.data_ingresso"
                                    ></ion-datetime>
                                </ion-item>
                            </ion-col>
                        </ion-row>

                        <ion-row>
                            <ion-col size="12">
                                <ion-item>
                                    <ion-label>
                                        Ora ingresso
                                        <ion-text color="danger"><strong>*</strong></ion-text>
                                    </ion-label>
                                    <ion-datetime
                                        name="start_time"
                                        display-format="HH:mm"
                                        picker-format="HH:mm"
                                        slot="end"
                                        doneText="OK"
                                        cancelText="Annulla"
                                        v-model="presenza.ora_ingresso"
                                    ></ion-datetime>
                                </ion-item>
                            </ion-col>
                        </ion-row>

                        <ion-row>
                            <ion-col size="12">
                                <ion-item>
                                    <ion-label>
                                        Data uscita
                                        <ion-text color="danger"><strong>*</strong></ion-text>
                                    </ion-label>
                                    <ion-datetime
                                        name="date_end"
                                        display-format="DD/MM/YYYY"
                                        picker-format="DD/MM/YYYY"
                                        slot="end"
                                        doneText="OK"
                                        cancelText="Annulla"
                                        v-model="presenza.data_uscita"
                                    ></ion-datetime>
                                </ion-item>
                            </ion-col>
                        </ion-row>

                        <ion-row>
                            <ion-col size="12">
                                <ion-item>
                                    <ion-label>
                                        Ora uscita
                                        <ion-text color="danger"><strong>*</strong></ion-text>
                                    </ion-label>
                                    <ion-datetime
                                        name="end_time"
                                        display-format="HH:mm"
                                        picker-format="HH:mm"
                                        slot="end"
                                        doneText="OK"
                                        cancelText="Annulla"
                                        v-model="presenza.ora_uscita"
                                    ></ion-datetime>
                                </ion-item>
                            </ion-col>
                        </ion-row>

                        <ion-row>
                            <ion-col size="12">
                                <ion-item>
                                    <ion-label position="floating">
                                        Azienda
                                        <ion-text color="danger"><strong>*</strong></ion-text>
                                    </ion-label>
                                    <ion-select name="azienda" cancel-text="Annulla" ok-text="Conferma" v-model="presenza.azienda" interface="action-sheet">
                                        <ion-select-option v-for="azienda in aziende" :key="azienda.aziende_id" :value="azienda.aziende_id">
                                            {{ azienda.aziende_ragione_sociale }}
                                        </ion-select-option>
                                    </ion-select>
                                </ion-item>
                            </ion-col>
                        </ion-row>

                        <ion-row>
                            <ion-col size="12">
                                <ion-item>
                                    <ion-label position="floating">
                                        Cliente
                                        <ion-text color="danger"><strong>*</strong></ion-text>
                                    </ion-label>
                                    <ion-select
                                        name="cliente"
                                        cancel-text="Annulla"
                                        ok-text="Conferma"
                                        v-model="presenza.cliente"
                                        interface="action-sheet"
                                        @ionChange="selectedCustomer($event)"
                                    >
                                        <ion-select-option v-for="cliente in clienti" :key="cliente.customers_id" :value="cliente.customers_id">
                                            {{ cliente.customers_company }}
                                        </ion-select-option>
                                    </ion-select>
                                </ion-item>
                            </ion-col>
                        </ion-row>

                        <ion-row>
                            <ion-col size="12">
                                <ion-item>
                                    <ion-label position="floating">
                                        Sede
                                        <ion-text color="danger"><strong>*</strong></ion-text>
                                    </ion-label>
                                    <ion-select
                                        name="sede"
                                        cancel-text="Annulla"
                                        ok-text="Conferma"
                                        v-model="presenza.sede"
                                        interface="action-sheet"
                                        @ionChange="selectedSede($event)"
                                    >
                                        <ion-select-option
                                            v-for="sede in sedi"
                                            :key="sede.customers_shipping_address_id"
                                            :value="sede.customers_shipping_address_id"
                                        >
                                            {{ sede.customers_shipping_address_city }}
                                            {{ sede.customers_shipping_address_nome }}
                                        </ion-select-option>
                                    </ion-select>
                                </ion-item>
                            </ion-col>
                        </ion-row>

                        <ion-row>
                            <ion-col size="12">
                                <ion-item>
                                    <ion-label position="floating">
                                        Reparto
                                        <ion-text color="danger"><strong>*</strong></ion-text>
                                    </ion-label>
                                    <ion-select name="reparto" cancel-text="Annulla" ok-text="Conferma" v-model="presenza.reparto" interface="action-sheet">
                                        <ion-select-option v-for="reparto in reparti" :key="reparto.reparto_id" :value="reparto.reparto_id">
                                            {{ reparto.reparto_nome }}
                                        </ion-select-option>
                                    </ion-select>
                                </ion-item>
                            </ion-col>
                        </ion-row>

                        <ion-row>
                            <ion-col>
                                <ion-item>
                                    <ion-label position="floating"> Note </ion-label>
                                    <ion-textarea type="text" rows="3" v-model="presenza.note"> </ion-textarea>
                                </ion-item>
                            </ion-col>
                        </ion-row>

                        <ion-row>
                            <ion-col size="6">
                                <ion-button color="danger" size="default" expand="block" @click="closeModal">
                                    Chiudi
                                </ion-button>
                            </ion-col>
                            <ion-col size="6">
                                <ion-button color="success" size="default" type="submit" expand="block">
                                    Crea presenza
                                </ion-button>
                            </ion-col>
                        </ion-row>
                    </ion-list>
                </form>
            </ion-grid>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonButton,
    modalController,
    IonGrid,
    IonRow,
    IonCol,
    IonList,
    IonItem,
    IonLabel,
    IonDatetime,
    IonTextarea,
    IonSelect,
    IonSelectOption,
    IonText,
} from "@ionic/vue";
import { defineComponent, ref, reactive, onMounted } from "vue";

//import axios from "axios";
import moment from "moment";

import { getCustomers } from "../services/localbase";
import utils from "../services/utils";
import { openToast } from "../services/toast";

import apiAziende from "../services/aziende";
import apiClienti from "../services/clienti";
import apiReparti from "../services/reparti";
import apiPresenze from "../services/presenze";

import { useRouter } from "vue-router";

export default defineComponent({
    name: "ModalPresenza",
    //   props: {
    //     cliente: {
    //       type: Object,
    //       required: true,
    //     },
    //     impianti: {
    //       type: Object,
    //       required: true,
    //     },
    //   },
    components: {
        IonPage,
        IonContent,
        IonHeader,
        IonTitle,
        IonToolbar,
        IonButtons,
        IonButton,
        IonBackButton,
        IonGrid,
        IonRow,
        IonCol,
        IonList,
        IonItem,
        IonLabel,
        IonDatetime,
        IonTextarea,
        IonSelect,
        IonSelectOption,
        IonText,
    },
    setup(props, context) {
        const router = useRouter();

        const start_date = new Date();
        const end_hour = new Date();
        const start_hour = new Date(end_hour);
        start_hour.setHours(start_hour.getHours() - 1);

        const userID = JSON.parse(localStorage.getItem("userInfo")).users_id; //set userID in user field
        const presenza = reactive({
            user: "", //from logged user
            data_ingresso: start_date.toISOString(),
            ora_ingresso: start_hour.toISOString(),
            data_uscita: start_date.toISOString(),
            ora_uscita: end_hour.toISOString(),
            azienda: "",
            cliente: "",
            sede: "",
            reparto: "",
            note: "",
        });
        const aziende = ref([]);
        const clienti = ref([]);
        const sedi = ref([]);
        const reparti = ref([]);

        const customersDB = ref([]);
        const successResponse = ref(false);

        /*
         *
         * Get sedi for the selected customer and populate sedi select
         *
         * */
        function getSedi(customer_id) {
            /* apiClienti
                .getClienteSedi(customer_id) */
            apiClienti
                .getClienteSedi(customer_id, userID)
                .then((response) => {
                    sedi.value = response;
                    //conducente.value = response;
                })
                .catch((error) => {
                    error.value = true;
                    openToast("Errore durante la richiesta delle sedi per il cliente selezionato", "danger");
                })
                .finally(() => {
                    //console.log("fine richiesta sedi");
                });
        }

        function selectedCustomer(event) {
            const customer_id = event.detail.value;
            presenza.sede = "";
            presenza.reparto = "";
            getSedi(customer_id);
        }

        /*
         *
         * Get reparto for the selected sede and populate reparto select
         *
         * */
        function getReparti(sede_id, cliente_id) {
            /* apiReparti
                .getSedeReparti(sede_id, cliente_id) */
            apiReparti
                .getSedeReparti(sede_id, cliente_id, userID)
                .then((response) => {
                    reparti.value = response;
                })
                .catch((error) => {
                    error.value = true;
                    openToast("Errore durante la richiesta dei reparti per la sede selezionata", "danger");
                })
                .finally(() => {
                    //console.log("fine richiesta reparti");
                });
        }

        function selectedSede(event) {
            const sede_id = event.detail.value;
            const cliente_id = presenza.cliente;
            presenza.reparto = "";
            getReparti(sede_id, cliente_id);
        }

        /**
         *
         * Get clienti from localDB or api
         *
         */
        async function getClienti() {
            /* const customersDB = await getCustomers();
            if (customersDB.length !== 0) {
                clienti.value = customersDB;
            } else {
                apiClienti
                    .getClienti()
                    .then((response) => {
                        clienti.value = response;
                    })
                    .catch((error) => {
                        //alert(error);
                        error.value = true;
                        openToast("Errore durante la richiesta dei dati", "danger");
                        console.error(error);
                    })
                    .finally(() => {
                        //console.log("fine");
                    });
            } */
            apiClienti
                .getClienti(userID)
                .then((response) => {
                    clienti.value = response;
                })
                .catch((error) => {
                    //alert(error);
                    error.value = true;
                    openToast("Errore durante la richiesta dei dati", "danger");
                    console.error(error);
                })
                .finally(() => {
                    //console.log("fine");
                });
        }

        getClienti();

        /**
         *
         * Close modal after form data submit
         *
         */
        async function closeModalOnSubmit(successResponse, res) {
            if (successResponse.value) {
                //console.log("successResponse ", successResponse.value);
                await modalController.dismiss(res);
            } else {
                //console.log("successResponse ", successResponse.value);
                await modalController.dismiss();
            }
        }

        /**
         *
         * Handle close modal by clicking on Chiudi button
         *
         */
        const closeModal = () => {
            modalController.dismiss();
        };

        /**
         *
         * Create new presenza
         *
         */
        function creaPresenza() {
            const data_ingresso_formatted = moment(presenza.data_ingresso).format("YYYY-MM-DD");
            const data_uscita_formatted = moment(presenza.data_uscita).format("YYYY-MM-DD");
            const ora_ingresso_formatted = moment(presenza.ora_ingresso).format("HH:mm:ss");
            const ora_uscita_formatted = moment(presenza.ora_uscita).format("HH:mm:ss");
            const data_ora_ingresso = data_ingresso_formatted + " " + ora_ingresso_formatted;
            const data_ora_uscita = data_uscita_formatted + " " + ora_uscita_formatted;

            presenza.user = userID; //set user as logged user id
            if (!presenza.azienda || !presenza.cliente || !presenza.sede || !presenza.reparto) {
                openToast("Azienda, Cliente, Sede e Reparto sono obbligatori", "danger");
            } else {
                const data = new FormData();
                data.append("presenze_user", presenza.user); //logged user
                data.append("presenze_customer", presenza.cliente);
                data.append("presenze_sede", presenza.sede);
                data.append("presenze_reparto", presenza.reparto);
                data.append("presenze_azienda", presenza.azienda);
                data.append("presenze_data_ora_ingresso", data_ora_ingresso);
                data.append("presenze_data_ora_uscita", data_ora_uscita);
                data.append("presenze_note", presenza.note);

                apiPresenze
                    .savePresenza(data)
                    .then((response) => {
                        if (response.data.status == 8) {
                            openToast(response.data.message, "danger");
                        } else {
                            const res = response.data.data[0];
                            successResponse.value = true;
                            // const localInterventi = JSON.parse(
                            //   localStorage.getItem("interventi")
                            // );
                            // localInterventi.push(res);
                            closeModalOnSubmit(successResponse, res);
                        }
                    })
                    .catch((error) => {
                        // handle error
                        console.error(error);
                        openToast("Errore durante la creazione della presenza", "danger");
                    })
                    .finally(() => {
                        //console.log("fine creazione presenza");
                    });
            }
        }

        onMounted(() => {
            apiAziende
                .getAziende(userID)
                .then((response) => {
                    aziende.value = response;
                    //conducente.value = response;
                })
                .catch((error) => {
                    error.value = true;
                    openToast("Errore durante la richiesta delle aziende", "danger");
                })
                .finally(() => {
                    //console.log("fine richiesta tecnici e conducente");
                });
        });

        return {
            presenza,
            creaPresenza,
            closeModal,
            customersDB,
            userID,
            aziende,
            clienti,
            selectedCustomer,
            sedi,
            selectedSede,
            reparti,
        };
    },
});
</script>

<style scoped>
.error_message {
    color: #eb445a;
}
.danger {
    color: #eb445a !important;
}

.example {
    border: 1px solid black;
}

.alert-wrapper {
    width: 90% !important;
}
</style>
