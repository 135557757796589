import axios from "axios";
const TOKEN = "Bearer 26d460b39301781b10d37af31e2e5a4a";

export default {
    async getAziende(userID: string) {
        const data = new FormData();
        data.append("where[]", `aziende_id in (select aziende_id from rel_azienda_dipendente where users_id = "${userID.toString()}")`);
        data.append("orderby", "aziende_ragione_sociale");
        data.append("orderdir", "asc");

        const response = await axios.post("/search/aziende", data, {
            headers: {
                Authorization: TOKEN,
            },
        });
        //console.log(response);
        return response.data.data;
    },
};
